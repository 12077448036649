import React from 'react'
import image from '../assets/images/IMG_0675.jpg'
import image1 from '../assets/images/IMG_0678.jpg'
import image3 from '../assets/images/IMG_0694.jpg'
import image4 from '../assets/images/IMG_0698.jpg'
import image5 from '../assets/images/IMG_0712.jpg'
import image6 from '../assets/images/IMG_0719.jpg'
import image7 from '../assets/images/IMG_0721.jpg'
import image8 from '../assets/images/IMG_0723.jpg'
import image9 from '../assets/images/IMG_0724.jpg'
import image10 from '../assets/images/IMG_0725.jpg'
import image11 from '../assets/images/IMG_0727.jpg'
import image12 from '../assets/images/IMG_0728.jpg'
import image13 from '../assets/images/IMG_0729.jpg'

import {ImageContainer, IMG, StyledLink} from './images.styles.js'

const images = [image, image1, image3, image4, image5, image6, image12, image13, image7, image8, image9, image10, image11]

const Images = () => {
    return (
        <div>
            <StyledLink to="/">Home</StyledLink>
        <div style={{display: 'flex', justifyContent: 'center', margin: '100px'}}>
            <ImageContainer>
        {images.map((img) => {
            return (<IMG src={img} />)
        })}
        </ImageContainer>
        </div>
        </div>
    )
}

export default Images