import React from 'react';
import './App.css';
import HaileyCover from './assets/images/IMG_0727.jpg'

import {Welcome, Name, GiantImage, Container, SecondWelcome, Dimensions, StyledLink} from './app.styles.js'

function App() {
  return (
    <Container>
      <StyledLink to="/images">Pictures</StyledLink>
      <Welcome>Please welcome</Welcome>
      <SecondWelcome> the newest McQuaid</SecondWelcome>
      <Name>Hailey Wren McQuaid</Name>
      <GiantImage src={HaileyCover}></GiantImage>
      <Dimensions>Hailey arrived at 1:14am on July 23rd. She measured in at 9lbs 3oz and 19in long. Mom and baby are both doing well.</Dimensions>
    </Container>
  )
}

export default App;
