import styled from "styled-components";
import {Link} from 'react-router-dom'

const IMG = styled.img`
    display: flex;
    width: 300px;
    margin: 20px;
    flex-wrap: wrap;
    text-align: center;
`

const ImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const StyledLink = styled(Link)`
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    text-decoration: none;
    :visited {
        color: white;
    }
`

export {IMG, ImageContainer, StyledLink}