import styled from 'styled-components'
import {Link} from 'react-router-dom'

const Welcome = styled.h1`
margin-top: 50px;
font-size: 56px;
-webkit-animation: fadeinout 10s linear forwards;
animation: fadeinout 10s linear forwards;


@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

@keyframes fadeinout {
    0%,100% { opacity: 0 }
    50% { opacity: 1 }
}

@media(max-width: 600px) {
    font-size: 40px;
    text-align: center;
}
`

const SecondWelcome = styled.h1`
font-size: 56px;
-webkit-animation: fadeinout 10s linear forwards;
animation: fadeinout 10s linear forwards;
margin-top: 0px;


@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

@keyframes fadeinout {
    0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

@media(max-width: 600px) {
    margin-top: -30px;
    font-size: 40px;
    text-align: center;
}
`

const Name = styled.h1`
    display: flex;
    -webkit-animation: fadeIn 17s linear forwards;
    animation: fadeIn 17s linear forwards;
    font-size: 63px;
    position: absolute;
    top: 50px;

    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media(max-width: 600px) {
        top: 40px;
        font-size: 48px;
        text-align: center;
    }
`

const GiantImage = styled.img`
    display: flex;
    -webkit-animation: fadeIn 17s linear forwards;
    animation: fadeIn 17s linear forwards;
    width: 400px;


    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media(max-width: 600px) {
        width: 325px;
        margin-top: 50px;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Dimensions = styled.h4`
    display: flex;
    text-align: center;
    margin: 25px 30px;
    -webkit-animation: fadeIn 17s linear forwards;
    animation: fadeIn 17s linear forwards;
    width: 400px;


    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media(max-width: 600px) {
        width: 325px;
        margin-top: 20px;
    }
`

const StyledLink = styled(Link)`
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    text-decoration: none;
    text-align: right;
    :visited {
        color: white;
    }

    -webkit-animation: fadeIn 17s linear forwards;
    animation: fadeIn 17s linear forwards;
    width: 400px;


    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        60% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media(max-width: 600px) {
        width: 325px;
        margin-top: 0px;
    }
`

export {Welcome, SecondWelcome, Name, GiantImage, Container, Dimensions, StyledLink}